// Header.js

import React, { useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-800 text-white py-6 flex justify-between items-center sticky w-full">
      <div className="text-3xl font-extrabold ml-6"></div>
      <nav className="mr-6 md:flex hidden">
        <ul className="flex space-x-6">
          <li>
            <a
              href="#home"
              className="hover:text-gray-300 text-lg font-semibold"
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="#introduction"
              className="hover:text-gray-300 text-lg font-semibold"
            >
              Introduction
            </a>
          </li>
          <li>
            <a
              href="#events"
              className="hover:text-gray-300 text-lg font-semibold"
            >
              Events
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="hover:text-gray-300 text-lg font-semibold"
            >
              Contact
            </a>
          </li>

          {/* Add more menu items as needed */}
        </ul>
      </nav>
      <div className="md:hidden block cursor-pointer" onClick={toggleMenu}>
        <svg
          className="w-8 h-8"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>
      {isMenuOpen && (
        <div className="md:hidden absodlute top-16 left-0 bg-gray-800 w-full text-center z-50">
          <ul className="flex flex-col space-y-6">
            <li>
              <a
                onClick={toggleMenu}
                href="#home"
                className="hover:text-gray-300 text-lg font-semibold"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={toggleMenu}
                href="#introduction"
                className="hover:text-gray-300 text-lg font-semibold"
              >
                Introduction
              </a>
            </li>
            <li>
              <a
                onClick={toggleMenu}
                href="#events"
                className="hover:text-gray-300 text-lg font-semibold"
              >
                Events
              </a>
            </li>
            <li>
              <a
                onClick={toggleMenu}
                href="#contact"
                className="hover:text-gray-300 text-lg font-semibold"
              >
                Contact
              </a>
            </li>

            {/* Add more menu items as needed */}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
