// Gallery.jsx

import React from "react";
import JourneyStart from "../jounreyStart.png";
import Tour1 from "../images/tour1.jpg";
import Tour2 from "../images/tour2.jpg";
import Tour3 from "../images/tour3.jpeg";
import Tour4 from "../images/tour4.jpg";
import Tour5 from "../images/tour5.jpg";
import Tour6 from "../images/tour6.jpg";
import Tour7 from "../images/tour7.jpg";
import Tour8 from "../images/tour8.jpg";
import Tour9 from "../images/tour9.jpeg";

import Tour10 from "../images/tour10.jpg";
import Tour11 from "../images/tour11.jpg";
import Tour12 from "../images/tour12.jpg";
import Tour13 from "../images/tour13.jpg";
import Tour14 from "../images/tour14.jpg";
import Tour15 from "../images/tour15.jpg";

import { LazyLoadImage } from "react-lazy-load-image-component";

const Gallery = () => {
  const galleryData = [
    {
      id: 0,
      imageSrc: JourneyStart,
      description:
        "Starting our campaign from Pokhara Phewa Lake (Me and Gautam Chapagain)",
    },
    {
      id: 1,
      imageSrc: Tour1,
      description: "At Shivapuri Nagarjun National Park Promoting our campaign",
    },
    {
      id: 2,
      imageSrc: Tour2,
      description:
        "Congratulatory Message from Ambassador of Nepal to Bangladesh (Mr. Ghanshyam Bhandari)",
    },
    {
      id: 3,
      imageSrc: Tour3,
      description: "Attending Cyclist event - Pedaling for life",
    },
    {
      id: 4,
      imageSrc: Tour4,
      description: "Promoting eco-friendly world",
    },
    {
      id: 5,
      imageSrc: Tour5,
      description: "At Nepal’s 1st pump track",
    },
    {
      id: 6,
      imageSrc: Tour6,
      description: "Promoting event - Eco-Friendly Pokhara",
    },
    {
      id: 7,
      imageSrc: Tour7,
      description: "Promoting our campaign around India",
    },
    {
      id: 8,
      imageSrc: Tour8,
      description:
        "Social awareness campaign emphasizing violence against women",
    },
    {
      id: 9,
      imageSrc: Tour9,
      description: "At event Pedal Revolution around Lalitpur",
    },
    {
      id: 10,
      imageSrc: Tour10,
      description: "Attending the cycling event at lalitpur municipality",
    },
    {
      id: 11,
      imageSrc: Tour11,
      description: "At hawada bridge kolkata",
    },
    {
      id: 12,
      imageSrc: Tour12,
      description: "Jeser boarder of bangladesh and india",
    },
    {
      id: 13,
      imageSrc: Tour13,
      description: "Using Ox at Bangladesh as mode of transportation",
    },
    {
      id: 14,
      imageSrc: Tour14,
      description: "At the capital city of Bangladesh (Dhaka)",

    },
    {
      id: 15,
      imageSrc: Tour15,
      description: "Visiting Dhaka with Gautam Chapagain",
    },
    // Add more gallery items as needed
  ];

  return (
    <section className="bg-gray-800 text-white p-10">
      <h1 className="text-3xl font-bold mb-8 text-center">Events Section</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 rounded-md">
        {galleryData.map((item) => (
          <div key={item.id} className=" bg-gray-700 rounded-lg">
            <LazyLoadImage
              src={item.imageSrc}
              alt={item.imageSrc}
              style={{ width: "100%" }}
              className="w-full h-96 object-faill rounded-t-lg"
              effect="blur"
            />
            <p className="text-lg text-center text-gray-100 my-4 justify-center">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
