// ContactUs.js

import React from "react";
import GifImage from "../message.gif";

const ContactUs = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
    // Get form data
    const formData = new FormData(event.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");
    // Construct the mailto link
    const mailtoLink = `mailto:${email}?subject=Message from ${name}&body=${message}`;
    // Open the user's default email client
    window.location.href = mailtoLink;
  };
  return (
    <section className="py-8 md:py-12 bg-gray-800">
      <div className="flex flex-row justify-center  items-center mb-8">
        <h2 className="text-3xl font-semibold text-white">Get in touch</h2>
        <img src={GifImage} alt="Envelope" className="w-16 h-16 -mt-8" />
      </div>
      {/* Contact Information */}
      <div className="w-full flex flex-col sm:flex-row gap-x-5 px-8">
        {/* Phone Card */}
        <div className="bg-white p-6 rounded-md shadow-md mb-4 sm:mb-0 flex-1">
          <h3 className="text-lg font-semibold mb-2">Phone</h3>
          <p className="text-gray-700">+977 9846110672</p>
        </div>

        {/* Email Card */}
        <div className="bg-white p-6 rounded-md shadow-md mb-4 sm:mb-0 flex-1">
          <h3 className="text-lg font-semibold mb-2">Email</h3>
          <p className="text-gray-700">keshabraj@gmail.com</p>
        </div>

        {/* Address Card */}
        <div className="bg-white p-6 rounded-md shadow-md flex-1">
          <h3 className="text-lg font-semibold mb-2">Address</h3>
          <p className="text-gray-700">Modi Parbat, Gandaki, Nepal</p>
        </div>
      </div>
      <div className="container flex flex-col items-center">
        {/* Contact Form */}
        <div className="w-full md:w-1/2 p-4 md:p-8">
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="block text-gray-100 font-medium">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Your Name"
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-100 font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Your Email"
                required
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-gray-100 font-medium"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="mt-1 p-2 w-full border rounded-md"
                placeholder="Your Message"
                required
              ></textarea>
            </div>
            <button
              onClick={() => {
                console.log("Aa");
              }}
              type="submit"
              className="bg-blue-500 w-full text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
