/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import ProfileImage from "../images/keshab.jpeg";

const Biography = () => {
  return (
    <section className="bg-gray-800 text-white p-10">
      <h1 className="text-2xl font-semibold mb-4 text-center">INTRODUCTION</h1>
      <div className="flex flex-col md:flex-row items-center">
        <img
          alt="Circular Image"
          src={ProfileImage}
          className="w-48 h-48 rounded-full"
        />
        <div className="ml-4 flex-1 justify-center">
          {/* Text Content */}
          <p className="text-gray-300 justify-center">
            Keshab Raj Sharma, born in 1989, is a dedicated advocate for harmony
            between humanity and the environment. With a passion for sports,
            culture, and eco-friendly living, Keshab and his friend Gautam Chapagain has traveled extensively to
            promote world peace and environmental conservation.
            <br />
            <br />
            Motivated by deep concern for the planet, Keshab embarked on a
            transformative journey — a World Cycle Tour. This symbolic
            expedition underscores his commitment to global unity and
            environmental preservation. <br />
            <br />
            A strong proponent of sustainable practices, Keshab's love for
            sports and culture fuels his mission to inspire positive change.
            Through his travels for world peace, he aims to raise awareness
            about the critical need for environmental conservation. <br />
            <br />
            Keshab's journey is both a physical adventure and a powerful symbol
            of unity. His story encourages individuals worldwide to adopt
            eco-friendly lifestyles for a more sustainable and peaceful future.
          </p>
          {/* Add more lines as needed */}
        </div>
      </div>
    </section>
  );
};

export default Biography;
