// App.js

import React from "react";
import Header from "./components/Header";
import Section1 from "./components/Section1";
import Biography from "./components/Biography";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Events from "./components/Events";

function App() {
  return (
    <div>
      <Header />
      <Section1 />
      <div id="introduction" className="pt-1">
        <Biography />
      </div>
      <div id="events" className="pt-1">
        <Events />
      </div>

      <div id="contact" className=" py-1">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
