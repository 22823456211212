// Section1.js

import React from "react";
import responsiveImage from "../bgImage.png"; // Replace with the actual path to your image
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const Section1 = () => {
  return (
    <section className="bg-gray-100">
        <LazyLoadImage
          alt="bg-iamge"
          // height={image.height}
          effect="blur"
          src={responsiveImage}
          className="w-full max-h-[500px] "

        />
    </section>
  );
};

export default Section1;
